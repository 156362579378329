<template>

<app-item route="Group" :id="item.id" id-name="group" :selected="selected" v-on:shiftclick="$emit('shiftclick')">

	<app-item-badge v-if="!isByCategory" :route="(category.id) ? 'Category' : 'Category.Groups'" :params="{category: category.id}" :image="(category.id) ? category.icon : false" :icon="category.icon" :color="category.background" :tooltip="category.title" />

	<app-item-badge :image="item.image" :invisible="!item.visible" />

	<app-item-title :text="$highlight(item.title, highlight)" />

	<app-item-count route="Group.Items" :params="{group: item.id}" tooltip="Manage items" :count="itemCount" />

	<app-item-drag v-if="canOrder" />

</app-item>

</template>

<script>

export default {

	props: ['item', 'highlight', 'canOrder', 'selected'],

	computed: {

		isByCategory: function() {

			return this.$route.meta.byCategory

		},

		category: function() {

			if (this.item.category) {

				return this.$store.getters['categories/find'](this.item.category)

			} else {

				return {
					id: 0,
					background: '0b234e',
					visible: false,
					icon: 'uncategorized',
					title: 'Library'
				}

			}

		},

		itemCount: function() {

			return this.$store.getters['items/find/group'](this.item.id).length

		}

	}

}

</script>

<style scoped>

</style>
